const COOKIE_ACTIVE_BLOG_ID = "activeBlogId"
const COLOR_MODE_ID = "colorMode"
const SUBSCRIBE_MODAL_SHOWN = "subscribeModalShown-"

const CREATOR_REFERRER_KEY = "creator-referrer"
const READER_REFERRER_KEY = "reader-referrer-"

const REFERRAL_EXPIRATION_DAYS = 7

const UTM_KEY = "utm-"
const UTM_EXPIRATION_DAYS = 30

import Cookies from "js-cookie"

export const getActiveBlogId = (): string | undefined =>
  Cookies.get(COOKIE_ACTIVE_BLOG_ID)

export const setActiveBlogId = (blogId: string): string | undefined =>
  Cookies.set(COOKIE_ACTIVE_BLOG_ID, blogId)

export const removeActiveBlogId = (): void =>
  Cookies.remove(COOKIE_ACTIVE_BLOG_ID)

export const getColorMode = (): string | undefined => Cookies.get(COLOR_MODE_ID)

export const setColorMode = (value: "dark" | "light"): string | undefined =>
  Cookies.set(COLOR_MODE_ID, value)

/**
 * We only want the subscribe modal to be auto-shown once per blog.
 * The first time we display it, we set a cookie to remember that it has been shown
 * so we don't show it again.
 */
export const subscribeModalAlreadyShown = (
  blogId: string
): string | undefined => Cookies.get(SUBSCRIBE_MODAL_SHOWN + blogId)

/**
 * We only want the subscribe modal to be auto-shown once per blog.
 * The first time we display it, we set a cookie to remember that it has been shown
 * so we don't show it again.
 */
export const setSubscribeModalShown = (blogId: string): string | undefined =>
  Cookies.set(SUBSCRIBE_MODAL_SHOWN + blogId, "true")

/**
 * Set the 'creator referrer' cookie to the referrer's wallet address.
 *
 * Note that this can happen anywhere on Paragraph (even our landing page).
 */
export const setCreatorReferrer = (
  wallet_address: string
): string | undefined =>
  Cookies.set(CREATOR_REFERRER_KEY, wallet_address, {
    expires: REFERRAL_EXPIRATION_DAYS,
  })

export const getCreatorReferrer = (): string | undefined =>
  Cookies.get(CREATOR_REFERRER_KEY)

/**
 * Set the 'reader referrer' cookie to the referrer's wallet address.
 *
 * Note that this is scoped to a single publication.
 *
 * Eg, if a reader clicks on a link to a publication, we set the cookie to the
 * referrer's wallet address but only for that publication.
 */
export const setReaderReferrer = (
  wallet_address: string,
  blogId: string
): string | undefined =>
  Cookies.set(`${READER_REFERRER_KEY}${blogId}`, wallet_address, {
    expires: REFERRAL_EXPIRATION_DAYS,
  })

export const getReaderReferrer = (blogId: string): `0x${string}` | undefined =>
  Cookies.get(`${READER_REFERRER_KEY}${blogId}`) as `0x${string}` | undefined

/**
 * Set the 'utm' cookie to the utm serialized string on a per-blog basis.
 * @param serializedUtmString Serialized utm string which includes params like `utm_source`, `utm_medium`, and others.
 */
export const setUtmCookie = (
  blogId: string,
  serializedUtmString: string
): string | undefined =>
  Cookies.set(`${UTM_KEY}${blogId}`, serializedUtmString, {
    expires: UTM_EXPIRATION_DAYS,
  })

/**
 * Fetches and returns the serialized UTM cookie string for a given blog.
 * @param blogId
 * @returns Serialized UTM cookie string.
 */
export const getUtmCookie = (blogId: string): string | undefined =>
  Cookies.get(`${UTM_KEY}${blogId}`) as string | undefined

const cookiesToClear = ["jwt", "activeTeamId", "activeBlogId"]

export const removeJwtCookies = (): void => {
  // Remove all relevant cookies.
  cookiesToClear.forEach((cookie) => {
    Cookies.remove(cookie)
    Cookies.remove(cookie, {
      path: "/",
      domain: ".paragraph.xyz",
      secure: true,
      samesite: "none",
    })
  })
}
